.sidebar {
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;
  max-width: 25vh;
}

.sidebar .top {
  height: 50px;
  display: flex;
  align-items: center;
}

.sidebar .top .logo {
  width: 20vh;
  height: auto;
  margin-left: 10px;
  margin-top: 5px;
}

.sidebar hr {
  height: 0;
  border: 0.5px solid rgb(230, 227, 227);
}

.sidebar .center {
  padding-left: 10px;
}

.sidebar .tittle {
  font-size: 13px;
}

.sidebar .center ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar .center ul .title {
  font-size: 10px;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}

.sidebar .center ul li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.sidebar .center ul li .icone {
  font-size: 25px;
  color: black;
  margin: 8px;
}

.sidebar .top span {
  font-size: 13px;
  font-weight: 600;
  color: #888;
  margin-left: 10px;
}

.sidebar .bottom {
  display: flex;
  align-items: center;
  margin: 10px;
}

.sidebar .center ul li span {
  font-size: 15px;
  color: black;
}

.sidebar .botao {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.botaoRoxo {
  background-color: purple;
  color: black;
  border-radius: 50px;
  width: 15vh;
  height: 3vh;
}

.btns-solict2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search-input-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 3rem 0;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .btns-solict2 {
    margin-left: 110px;
    margin-top: 20px;
    margin-bottom: 20px;

  }

}

@media screen and (max-width: 768px) {
  .btns-solict2 {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .search-input-admin {
    width: 100%;
    margin: 1rem 0;
  }
}

@media screen and (max-width: 378px) {
  .btns-solict2 {
    display: flex;
    justify-content: flex-start;
  }
}

.expo-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.promotor-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #764faa;
  background: #764faa;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;

}

.promotor-btn:disabled {
  cursor: not-allowed;
}

.montador-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #22da29;
  background: #22da29;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.proj-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #ddcd1b;
  background: #ddcd1b;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.expo-btn1,
.promotor-btn1,
.montador-btn1,
.proj-btn1 {
  min-width: 130px;
  height: 40px;
  color: #aaaaaa;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #f1f1f1;
  background: #f1f1f1;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.expo-btn1:hover,
.promotor-btn1:hover,
.montador-btn1:hover,
.proj-btn1:hover,
.expo-btn:hover,
.promotor-btn:hover,
.montador-btn:hover,
.proj-btn:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}