.info-inicial {
  display: block;
  align-items: center;
  font-size: 12px;
  margin: 50px 25px;
  color: #484747;
}

.info > img {
  width: 50%;
}

.img-peq {
  width: 40% !important;
}

.info-inicial .texto h2 {
  font-family: "Montserrat-extra";
}

.info-inicial .texto p {
  font-family: "Gotham";
  color: #313131;
  font-size: 20px;
  margin-bottom: 20px;
}

.info-inicial .texto p strong {
  font-family: "Gotham-bold";
}

.passos img {
  width: 100%;
  margin-top: 100px;
}

/* CONHEÇA MAIS */
.conheca-mais p,
.tem-interesse .info-interesse p {
  font-family: "Gotham";
  font-size: 15px;
}

.texto > h2 {
  margin-bottom: 30px;
  font-size: 35px;
}

.conheca-mais {
  margin: 60px 0;
}

.conheca-mais h2 {
  font-size: 30px;
  font-family: "Montserrat-extra";
}

.conheca-mais .campos .campo {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 30px 0;
}

.conheca-mais .campos .campo h2 {
  margin-bottom: 20px;
}

.conheca-mais .campos .campo .elipse-campo {
  background-color: #f6f6f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 60px;
  margin-bottom: 20px;
}

.tem-interesse {
  background-color: #f6f6f6;
  display: flex;

  align-items: center;

  justify-content: space-between;

  flex-direction: column;

  padding: 35px;
}

.tem-interesse .info-interesse h2 {
  font-family: "Montserrat-extra";

  color: #717171;

  margin: 25px 0;
}

.texto-campo > h2 {
  text-align: center;
}

/* FIM MAIS */

/* TIMELINE */
:root {
  --primary-color: #212121;
  --background-color: #111;
  --font: sans-serif;
}

/* Timeline Container */

.timeline {
  /* background: var(--primary-color); */
  margin: 53px 30px;
  padding: 20px;
  display: block;
}

.card {
  position: relative;
  max-width: 400px;
}

.card:nth-child(odd) {
  padding: 30px 30px 30px 30px;
}

.card:nth-child(even) {
  padding: 30px 30px 30px 30px;
}

.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #636363;
}

.card:nth-child(odd)::before {
  left: 0px;
  top: -3.5px;
  bottom: -4.5px;
  border-width: 2px 0 2px 2px;
  border-radius: 50px 0 0 50px;
}

.card:nth-child(even)::before {
  right: 0;
  top: 2px;
  bottom: 2px;
  border-width: 2px 2px 2px 0;
  border-radius: 0 50px 50px 0;
}

.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

.info {
  display: flex;
  flex-direction: column;
  /* background: #f6f6f6; */
  color: gray;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}

.titler {
  color: #ff8f15;
  position: relative;
  font-family: "Montserrat-extra";
  font-size: 30px;
}

.titler::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  border: 3px solid #ff8f15;
}

.card:nth-child(even) > .info > .title {
  text-align: right;
}

.card:nth-child(odd) > .info > .title::before {
  left: -45px;
}

.card:nth-child(even) > .info > .title::before {
  right: -45px;
}

/* FIM TIMELINE */

.fluxo-desk {
  display: none;
}

.fluxo-mob {
  display: flex;
  flex-direction: column;
}

.p-linha {
  width: 100%;

  text-align: center;

  border-bottom: 1px solid #e0e0e0;

  line-height: 0.1em;

  margin: 10px 0 20px;
}

.imagem-info img {
  width: 70%;
}

.imagem-info {
  display: flex;
  justify-content: center;
}

.p-linha span {
  font-family: "Montserrat-extra";
  font-size: 25px;
  background: #fff;
  padding: 0 10px;
}

/* TIMELINE 2 */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600");

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 60px;
  text-align: center;
}

.timeline {
  position: relative;
  margin: 50px auto;
  padding: 40px 0;
  width: 1000px;
  box-sizing: border-box;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}

.timeline ul {
  padding: 0;
  margin: 0;
}

.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}

.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}

.content {
  padding-bottom: 20px;
}

.timeline ul li:nth-child(odd):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  right: -6px;
  background: #ff8f15;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgb(233 212 33 / 20%);
}

.timeline ul li:nth-child(even):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: #ff8f15;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.2);
}

.timeline ul li h3 {
  padding: 0;
  font-family: "Gotham";
  margin: 0;
  font-size: 20px;
  color: #484747;
  font-weight: 800;
}

.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
  font-family: "Gotham";
  line-height: 20px;
  font-size: 15px;
}

.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 25px;
}

.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 12px;
  right: -165px;
  margin: 0;
  font-size: 20px;
  font-family: "Gotham-bold";
  /* padding: 8px 16px; */
  /* background: #ff8f15; */
  color: rgb(72 71 71);
  border-radius: 18px;
  /* box-shadow: 0 0 0 3px rgb(233 187 33 / 30%); */
}

h2 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #414141;
}

.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 12px;
  right: -165px;
  margin: 0;
  font-size: 20px;
  font-family: "Gotham-bold";
  /* padding: 8px 16px; */
  /* background: #ff8f15; */
  color: rgb(72 71 71);
  border-radius: 18px;
  /* box-shadow: 0 0 0 3px rgb(233 187 33 / 30%); */
}

.banner-desk {
  display: none;
}

/* overlay banner inicio */
.banner-mob {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.banner-desk {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: url(../../images/banner-inicio.png) center no-repeat;
  background-size: cover;
  text-align: center;
}

.banner-mobile {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: url(../../images/banner-inicio-mob.png) center no-repeat;
  background-size: cover;
  text-align: center;
  box-shadow: 0px 4px 14px 0px #00000040;
  width: 100%;

  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -110%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.banner-mob {
  opacity: 1;
}

.middle {
  opacity: 1;
  width: 85%;
}

.text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.banner-inicio h2 {
  font-size: 34px;
  font-family: "Montserrat-extra";
  text-align: start;
}

/* .banner-inicio .linha-row {
  justify-content: space-between;
} */

.banner-inicio .face-banner {
  width: 60%;
  margin-top: 60px;
}

@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .timeline {
    width: 100%;
    padding-bottom: 0;
  }

  h1 {
    font-size: 40px;
    text-align: center;
  }

  .timeline:before {
    left: 20px;
    height: 96%;
  }

  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .timeline ul li:nth-child(odd):before,
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }

  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}

.card-3-img-mob {
  width: 75%;
  margin-top: 100px;
}

.card-3-img {
  width: 0%;
}

.mg-40 {
  margin-top: 20% !important;
}

.text-expo {
  color: #ff8400 !important;
}

@media screen and (min-width: 600px) {
  .mg-40 {
    margin-top: 38% !important;
  }

  .timeline {
    /* background: var(--primary-color); */

    display: none;
  }

  .fluxo-desk {
    display: flex;
  }

  .fluxo-mob {
    display: none;
  }

  .p-linha {
    width: 100%;

    text-align: center;

    border-bottom: 1px solid #e0e0e0;

    line-height: 0.1em;

    margin: 10px 0 20px;
  }

  .p-linha span {
    font-family: "Montserrat-extra";
    font-size: 30px;
    background: #fff;

    padding: 0 10px;
  }

  .info-inicial {
    display: flex;
    margin: 130px 60px;

    justify-content: space-between;
  }

  .info-inicial .texto h2 {
    font-size: 46px;
  }

  .info-inicial .texto p {
    font-size: 16px;
  }

  .info-inicial .imagem-info {
    text-align: end;
  }

  .info-inicial .imagem-info img {
    width: 75%;
  }

  /* CONHEÇA MAIS */
  .conheca-mais p,
  .tem-interesse .info-interesse p {
    font-family: "Gotham";
    font-size: 15px;
  }

  .texto > h2 {
    margin-bottom: 30px;
  }

  .conheca-mais {
    margin: 160px 0;
  }

  .conheca-mais h2 {
    font-size: 30px;
    font-family: "Montserrat-medium";
  }

  .conheca-mais .campos .campo {
    padding: 20px 10px;

    display: flex;

    align-items: center;

    justify-content: space-between;

    flex-direction: column;

    /* text-align: center; */

    margin: 50px 0;
  }

  .conheca-mais .campos .campo h2 {
    margin-bottom: 20px;
  }

  .conheca-mais .campos .campo .elipse-campo {
    background-color: #f6f6f6;

    border-radius: 50%;

    display: flex;

    align-items: center;

    justify-content: center;

    width: 100px;

    height: 100px;

    padding: 60px;

    margin-bottom: 20px;
  }

  .tem-interesse {
    background-color: #f6f6f6;

    display: flex;

    align-items: center;

    justify-content: space-between;

    flex-direction: column;

    padding: 35px;
  }

  .tem-interesse .info-interesse h2 {
    font-family: "Montserrat-extra";

    color: #717171;

    margin: 25px 0;
  }

  .texto-campo > h2 {
    text-align: center;
  }

  .banner-mob {
    display: none;
  }

  /* overlay banner inicio */
  .banner-desk {
    box-shadow: 0px 4px 14px 0px #00000040;
    opacity: 1;
    display: block;
    width: 100%;
    height: 590px;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    text-align: center;
  }

  .banner-desk {
    opacity: 1;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    height: 600px;
    justify-content: space-around;
  }

  .middle {
    opacity: 1;
    width: 85%;
  }

  .text {
    background-color: #04aa6d;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }

  .text-expo {
    color: #ff8400 !important;
  }

  .banner-inicio h2 {
    font-size: 58px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #414141;
    text-align: start;
    margin-top: 214px;
    /* width: 60%; */
  }

  .banner-inicio .linha-row {
    /* position: absolute; */
    display: flex;
    margin-top: 40px;
    align-items: center;
    padding: 20px;
    justify-content: right;
  }

  .banner-inicio .face-banner {
    width: 200px;
    margin-top: 0px;
  }

  /* .timeline */
  .cd-container {
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
    background: #ffffff;
    padding: 0 10%;
    border-radius: 12px;
  }

  .cd-container::after {
    content: "";
    display: table;
    clear: both;
  }

  #cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  #cd-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 4px;
    background: #697a8d;
  }

  @media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }

    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }

  .cd-timeline-block {
    position: relative;
    margin: 2em 0;
  }

  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 0.5em 0;
    }

    .cd-timeline-block:first-child {
      margin-top: 0;
    }

    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }

  .cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #a7a7a7, inset 0 2px 0 rgb(0 0 0 / 8%),
      0 3px 0 4px rgb(0 0 0 / 5%);
  }

  .cd-timeline-img {
    background: #646464;
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 25px;
      height: 25px;
      left: 50.3%;
      margin-left: -15px;
      margin-top: 8px;
    }
  }

  .cd-timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background: #e6e6e6;
    border-radius: 12px;
    padding: 1em;
  }

  .card-3-img-mob {
    width: 0%;
  }

  .card-3-img {
    position: absolute;
    left: 20%;
    width: 60%;
  }

  .timeline-content-info {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 2px;
  }

  .timeline-content-info i {
    margin-right: 5px;
  }

  .timeline-content-info-title,
  .timeline-content-info-date {
    width: calc(50% - 2px);
    display: inline-block;
  }

  @media (max-width: 500px) {
    .timeline-content-info-title,
    .timeline-content-info-date {
      display: block;
      width: 100%;
    }
  }

  .content-skills {
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-skills li {
    background: #40484d;
    border-radius: 2px;
    display: inline-block;
    padding: 2px 10px;
    color: rgba(255, 255, 255, 0.7);
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
  }

  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }

  .cd-timeline-content h2 {
    color: #414141;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .cd-timeline-content p,
  .cd-timeline-content .cd-date {
    color: #414141;
    font-size: 13px;
    font-size: 0.8125rem;
  }

  .cd-timeline-content .cd-date {
    display: inline-block;
  }

  .cd-timeline-content p {
    margin: 0px 0;
    line-height: 1.6;
  }

  .cd-timeline-content::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #e5e5e5;
  }

  @media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
      font-size: 20px;
      font-size: 1.3rem;
    }

    .cd-timeline-content h2 span {
      color: #ff8400;
      margin-right: 5px;
    }

    .cd-timeline-content .cd-read-more,
    .cd-timeline-content .cd-date {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }

  @media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      color: white;
      margin-left: 0;
      padding: 1.6em;
      width: 36%;
      margin: 0 5%;
    }

    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #e5e5e5;
    }

    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 16px;
      font-size: 1rem;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #e5e5e5;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }
}

/* .timeline */
.cd-container {
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
  background: #ffffff;
  padding: 0 10%;
  border-radius: 12px;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 5em;
}

#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25px;
  height: 100%;
  width: 4px;
  background: #697a8d;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 10em;
  }

  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: -1.5em 0;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 17px;
  left: 22px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #a7a7a7, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
}

.cd-timeline-content h2 span {
  color: #ff8400;
  margin-right: 10px;
}

.cd-timeline-img {
  background: #646464;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 10px;
    height: 10px;
    left: 50.9%;
    margin-left: -15px;
    margin-top: 8px;
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  margin-right: 30px;
  background: #e6e6e6;
  border-radius: 12px;
  padding: 1em;
}

.timeline-content-info {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.timeline-content-info i {
  margin-right: 5px;
}

.timeline-content-info-title,
.timeline-content-info-date {
  width: calc(50% - 2px);
  display: inline-block;
}

@media (max-width: 500px) {
  .timeline-content-info-title,
  .timeline-content-info-date {
    display: block;
    width: 100%;
  }
}

.content-skills {
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content-skills li {
  background: #40484d;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 10px;
  color: rgba(255, 255, 255, 0.7);
  margin: 3px 2px;
  text-align: center;
  flex-grow: 1;
}

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-content h2 {
  color: #414141;
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 5px;
}

.cd-timeline-content p,
.cd-timeline-content .cd-date {
  color: #414141;
  font-size: 13px;
  font-size: 0.8125rem;
}

.cd-timeline-content .cd-date {
  display: inline-block;
}

.cd-timeline-content p {
  margin: 0px 0px 0em;
  line-height: 1.6;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #e5e5e5;
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    /* font-size: 1.3rem; */
  }

  .cd-timeline-content h2 span {
    color: #ff8400;
    margin-right: 5px;
  }

  .cd-timeline-content p {
    font-size: 15px;
  }

  .cd-timeline-content .cd-read-more,
  .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    color: white;
    margin-left: 0;
    padding: 1.6em;
    width: 36%;
    margin: 0 5%;
  }

  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #e5e5e5;
  }

  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #e5e5e5;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
}

@media (max-width: 599px) {
  .banner-desk {
    display: none;
  }
}

@media (min-width: 599px) {
  .banner-mobile {
    display: none;
  }
}

.banner-mobile h2 {
  font-size: 34px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #414141;
  padding-left: 5px;
}

.banner-mobile .title-works {
  margin-top: 100px;
}


.bottom {
  margin-left: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150vh;
  align-items: center;
  text-align: left;
  height: auto;
}

.btn-sender {
  width: 200px;
  margin-left: 15px;
  margin-right: auto;
  border-radius: 50px;
  background: #414141;
  height: 51px;
  border-color: #414141;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  margin-top: 25px;
}

.Input-box {
  background-color: #dfdfdf;
  width: 25vh;
  height: 4vh;
  border-radius: 50px;
  border: none;
}