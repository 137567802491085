.expositor6 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 59rem;
}

.expo1 {
  display: flex;
}

.Step {
  background-color: white;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%) !important;
}

#back-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  font-family: "Montserrat";
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #c4c4c4;
  background: #c4c4c4;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  text-transform: lowercase;
  font-weight: 400;
  font-size: 16px;
}

#back-btn:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

#next-btn {
  min-width: 130px;
  height: 40px;
  color: white;
  font-family: "Montserrat";
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  text-transform: lowercase;
  font-weight: 400;
  font-size: 16px;
}

#next-btn:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

#send-btn {
  width: 80px;
  height: 40px;
  color: white;
  font-family: "Montserrat";
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #09c500;
  background: #09c500;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  text-transform: lowercase;
  font-weight: 400;
  font-size: 16px;
}

#send-btn:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.add-btn {
  /* min-width: 130px; */
  height: 40px;
  color: white;
  font-family: "Montserrat";
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  text-transform: lowercase;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
}

.add-btn:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}


/* .MuiButtonBase-root {
  position: inherit !important;
}

.MuiStep-alternativeLabel {
  position: inherit !important;
}

.MuiStepConnector-alternativeLabel {
  display: none;
} */
.makeStyles-button-1 {
  margin-right: 0px !important;
}

.makeStyles-button-6 {
  margin-right: 0px !important;
}

.title-step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.reg-feira {
  width: 100%;
  text-align: center;
}

.input-warning1,
.input-warning2 {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  color: red;
}

.title-step,
h2 {
  font-weight: 700;
  font-size: 35px;
  font-family: "Montserrat";
  color: orange;
}

.div-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 1080px) {
  .Container__Linaer--Stepper {
    display: block !important;
    padding: 0px;
  }
}

.cont-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid #757575;
  height: 39px;
  width: 50%;
  cursor: pointer;
  border-radius: 50px;
  background-color: #757575;
}

.cont-input:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  border-radius: 50px;
  background-color: #efeeee;
  height: 39px;
  width: 100%;
}

input[type="file"] {
  display: none;
}

.cont-input-name {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 40%;
}

.Container__Linaer--Stepper {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.div-input1,
.div-input2 {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.div-input3 {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 54px;
}

.btns-fluxo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 54px;
  margin-left: 54px;
}

.btn-switch {
  margin-left: 10px;
}

.add-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  border-radius: 50px;
  background-color: #efeeee;
  border: #efeeee;
  height: 39px;
  width: 100%;
}

/* .div-add-text {
  display: flex;
} */

.text-inpt6 {
  display: flex;
  align-items: center;
  text-align: start;
}

.css-13cymwt-control {
  background-color: #efeeee !important;
  border-radius: 50px !important;
}

.css-t3ipsp-control {
  background-color: #efeeee !important;
  border: #efeeee !important;
  border-radius: 50px !important;
  box-shadow: 0 0 0 1px #efeeee !important;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  width: 100%;
}

.step-project {
  background-color: #efeeee;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.release-project {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#alert-dialog-description {
  font-size: 2rem;
  font-family: "Montserrat";
  font-weight: bold;
}

@media (min-width: 769px) {

  .expositor6 {
    display: flex;

    margin-right: 20px !important;
  }

  .input-warning1 {
    display: none;
  }
}

@media (max-width: 768px) {
  .Step {
    margin-left: 20px;
  }

  .expositor6 {
    margin-left: 20px;
    margin-right: 20px;
    height: 96rem;
  }

  .div-input1,
  .div-input2 {
    display: flex;
    flex-direction: column;
  }

  .cont-input-name {
    width: 100%;
  }

  .div-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-switch {
    margin-left: 0px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .input-warning2 {
    display: none;
  }
}