@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
}

.content-banner {
  margin: 8rem;

}

.causas-content {
  display: flex;
  align-items: center;
  justify-content: center;
}



.causas {
  width: 100%;
  height: 100%;
  margin: 70px 10px;
  display: grid;
  grid-template-areas: "div";
  gap: 40px;
  justify-content: space-between;
}

.causas-img_lateral {
  margin-left: 10px;
}

.banner-inicial {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.banner-left {
  width: 100%;
}

.banner-left h2 {
  color: #414141;
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.banner-left h2:nth-last-child(2) {
  margin-bottom: 40px;
}

.text-button {
  color: white;
  border: none;
  border-radius: 50px;
  background: #ff8400;
  font-size: 17px;
  padding: 10px 20px;
  flex: 10;
  font-weight: 500;
}

.Container-text__banner {
  width: 100%;
}

.Container-text__banner p {
  width: 100%;
  font-size: 3rem;
  line-height: 1.5;
  padding: 20px;
  color: #000000;
}

.cause-description {
  padding-bottom: 3rem;


}

.description p {
  color: #444444;
  padding-left: 10px;
}



.Container-left {
  width: 50%;
}

.Container-left img {
  height: 400px;
}

.Container-right {
  width: 100%;
  height: 100%;
  gap: 20px;
  display: flex;
  padding: 50px;
  color: white;
  background-color: #9906cd;
  flex-direction: column;
  justify-content: center;
}

.Container-right h3 {
  color: #FFFFFF;
  font-weight: 800;
}

/* .evento-destaque {
  width: 100%;
  height: auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
} */

.evento-destaque h2 {
  font-size: 50px;
  line-height: 53px;
  margin-bottom: 20px;
}

.evento-destaque p {
  margin: 50px 0px 17px;
  font-size: 17px;
}

.evento-destaque p:first-of-type {
  margin: 0 0 30px;
  line-height: 25px;
  font-size: 17px;
}

.pesquisar-evento {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  color: #ff8400;
  font-size: 25px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pesquisar-evento div {
  border-right: none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 15px;
}

.pesquisar-evento form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 100;
  height: 41px;
  border-left: none;
}

.pesquisar-evento form input {
  width: 100%;
  padding: 6px;
}

.pesquisar-evento form input:focus {
  outline: none;
}

.pesquisar-evento form input,
.pesquisar-evento form button {
  padding: 5px 20px;
  background: none;
  border: none;
  font-size: 18px;
  height: 45px;
  color: black;
  margin-top: 38px;
}

.text-promo {
  color: #ff8400 !important;
}

.pesquisar-evento form button {
  background-color: #ff8400;
  color: white;
  text-transform: uppercase;
  padding: 5px 15px;
  height: 41px;
  margin: 0;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
}

.imagem-info {
  display: flex;
  flex-direction: column;
}

.imagem img {
  width: 100%;
  border-radius: 6px;
  height: auto;
}

.info {
  background-color: #6f6b69;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  color: #fff;
  width: 87%;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.causas {
  margin: 70px 10px;
  display: grid;
  grid-template-areas: "div";
  gap: 40px;
  justify-content: space-between;
}

.evento {
  border-radius: 15px;
  box-shadow: 0px 10px 50px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 10px 50px -12px rgba(0, 0, 0, 0.5);
  color: #444444;
  width: 100%;
  cursor: pointer;
}

.evento .img-evento {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.info-causas {
  border-radius: 0 0 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-causas h2 {
  margin-top: 20px;
  font-size: 25px !important;
  font-weight: bold;
}

.info-causas p {
  margin: 20 0px;
  font-size: 1.8rem;

}

.info-causas span {
  font-weight: bold;
}

/* .img-causa {
  padding: 0 25px;
} */

.img-causa img {
  height: 50px;
}

p.localizacao {
  margin: 20px 1vw 20px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.info-evento .olho {
  width: 30px;
}

.imagem {
  width: 325px;
  height: 325px;
}

.imagem img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* height: 454px; */
}

.parceiros_title {
  display: flex;
  justify-content: center;
  text-align: center;
}

.parceiros_title p {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}

.container_parceiros {
  background-color: #D9D9D9;
  width: 100%;
  min-height: 500px;
  margin-top: 50px;
  padding: 5% 0;
}

.container_parceiros .parceiros-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.container_parceiros .parceiros-logo img {
  margin: 0 50px;
}

/* desk */
@media screen and (min-width: 1084px) {
  .imagem-info {
    display: flex;
    flex-direction: row;
  }

  .imagem {
    width: 500px;
    height: 465px;
  }

  .imagem img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* height: 454px; */
  }

  .info {
    background-color: #6f6b69;
    border-radius: 0 10px 10px 0px;
    padding: 20px;
    color: #fff;
    width: 35%;
    height: 360px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }

  .banner-inicial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* .banner-inicial {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 160px 30px 130px;
    flex-direction: row;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  } */

  .Container-down {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 160px 30px 130px;
    flex-direction: row;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .Container-down h3 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 800;
  }

  .Container-down p {
    color: #FFFFFF;
    line-height: 23px;
    font-size: 15px;
  }

  .evento-destaque h2 {
    font-family: "Montserrat", sans-serif;
    color: #414141;
    line-height: 65px;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .evento {
    border-radius: 15px;
    box-shadow: 0px 10px 50px -12px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 10px 50px -12px rgba(0, 0, 0, 0.5);
    color: #444444;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .causas {
    margin: 130px 0px;
    display: grid;
    grid-template-areas: "div div div";
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 60px;
    justify-content: space-between;
    padding: 50px;
  }

  .evento-destaque img {
    width: 200px;
    margin-left: 60px;
  }


  .pesquisar-evento {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    color: #ff8400;
    height: 45px;
    font-size: 25px;
    background-color: #fff;
    width: 70%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  .pesquisar-evento form button {
    background-color: #ff8400;
    color: white;
    text-transform: uppercase;
    padding: 5px 26px;
    height: 45px;
    margin: 0;
    margin-top: 26px;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
  }
}

/* .hover-text {
  border-radius: 15px 15px 0 0 !important;
  width: 98% !important;
} */

/* .hover-bg {
  padding-left: 9px;
  width: 95.5%;
} */

/* .portfolio-item {
  display: flex;
  width: 100%;
} */

.causas .hover-bg .hover-text {
  border-radius: 15px 15px 0 0;
}

.portfolio-item {
  margin: -5px -15px 0 -14px;
  padding: 0;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-item .hover-bg {
  margin: 0px;
}

@media (max-width: 768px) {
  .content-banner {
    margin: 8rem 0;
  }

  .hover-bg .hover-text {
    width: 100%;
  }

  .banner-left h2 {
    font-size: 32px;
  }

  .container-causa {
    margin: 3rem 0;
  }

}



.localizacao p {
  margin-left: 33px;
}

.container-causa {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  justify-content: space-between;


}

.calend-event {
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-size: 14px;
  align-items: baseline;
}

.clock-event {
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-size: 14px;
  align-items: baseline;
}

.local-event {
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-size: 14px;
  align-items: baseline;
}

.name-event {
  font-family: sans-serif;
  font-size: 24px;
}

.info p {
  margin-left: 5px;
}

.container-event .data-hora p {
  margin-left: -23px;
}

.container-event .localizacao p {
  margin-left: 6px;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  width: 18px;
  height: 18px;
}

@media (max-width: 1084px) {
  .evento-destaque {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 85px;
  }

  .evento-destaque img {
    width: 200px;
  }

  .banner-inicial {
    display: flex;
    justify-content: space-between;
  }

  .text-button {
    padding: 10px 3px;
  }

  .evento-destaque h2 {
    font-family: "Montserrat", sans-serif;
    color: #414141;
    line-height: 65px;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .pesquisar-evento {
    max-width: 70%;
    height: 45px;
  }

  .imagem-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info {
    width: 75%;
  }

  .pesquisar-evento form button {
    margin-top: 30px;
    padding: 5px 26px;
  }

  .pesquisar-evento form input {
    margin-top: 38px;
  }

  .Container-left img {
    width: 100vw;
  }
}

@media (min-width: 638px) and (max-width: 1084px) {
  .causas {
    grid-template-areas: "div div";
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.look {
  width: 50% !important;
}


#causas .causas .img-card {
  border-radius: 15px 15px 0 0 !important;
  height: 220px;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1240px) {

  .img-causa {
    padding: 25px;
  }

  .container-causa {
    height: 280px;
    margin-bottom: 3rem;
  }
}