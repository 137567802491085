* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container1 {
  display: flex;
}

/* main {
  width: 100%;
  padding: 20px;
  height: 200vh;
} */

.sidebar1 {
  height: 120vh;
  width: 300px;
  transition: all 0.3s;
  position: fixed;
  z-index: 20;
}


.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  margin-left: 61px;
}

.bars {
  display: flex;
  /* margin-left: 70px; */
}

.link {
  display: flex;
  padding: 10px 15px;
  gap: 15px;
  align-items: center;
  transition: all 0.5s;
  text-decoration: none !important;
}

.link2 {
  padding: 5px 27px;
  gap: 15px;
  align-items: center;
  transition: all 0.5s;
  text-decoration: none !important;
  display: flex;
  align-items: center;
}

.link2:hover {
  background: #fff5e7;
  color: orange;
  transition: all 0.5s;
  border-radius: 5px;
  margin-left: 15px;
  text-decoration: none;
  width: 100%;
}

#active:active {
  background: #fff5e7;
  color: orange;
  width: 100%;
  text-decoration: none;
}

#active:active {
  background: #fff5e7;
  color: orange;
  width: 100%;
}

.icon,
.link_text {
  font-size: 16px;
  font-family: "Montserrat";
}

.profile-bar {
  background-color: redwhite;
  width: 93%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 5.875rem;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;
}

.expositor1 {
  display: flex;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 22rem;
  margin-top: 25px;
  align-items: center;
  justify-content: space-around;
}

.expositor2 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 12rem;
  margin-top: 25px;
}

.expositor3 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 12rem;
  margin-top: 25px;
}

.expositor2,
.warnings {
  font-weight: 200;
  font-size: 20px;
  color: #9b9ea8;
}

.overshadow {
  position: fixed;
  /* Sit on top of the page content */
  /* Hidden by default */
  width: 160%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 300px;
  transition: all 0.5s;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.expositor2,
.messages {
  color: black;
  font-weight: 300;
}

@media (min-width: 768px) {

  .expositor2,
  .expositor3 {
    display: flex;
    margin-left: 110px;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {

  .expositor2,
  .expositor3 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.cookie-modal {
  max-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 26px;
  border: none;
  background-color: #ff8401;
  box-shadow: 0 3px 1px -2px #ccc, 0 2px 2px 0 #ccc, 0 1px 5px 0 #ccc;
}

.cookie-modal:active {
  box-shadow: 0 4px 2px -3px #ccc, 0 4px 5px 1px #ccc, 0 2px 7px 1px #ccc;
}

.cookie-modal2 {
  max-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 26px;
  border: none;
  background-color: #22da29;
  box-shadow: 0 3px 1px -2px #ccc, 0 2px 2px 0 #ccc, 0 1px 5px 0 #ccc;
}

.cookie-modal2:active {
  box-shadow: 0 4px 2px -3px #ccc, 0 4px 5px 1px #ccc, 0 2px 7px 1px #ccc;
}

.menu-bar {
  cursor: pointer;
}

@media screen and (min-width: 959px) and (max-width: 978px) {
  .expositor1 {
    display: flex;
    flex-direction: row;
  }

  .expo-logo {
    width: 130px;
    margin-top: 10px;
  }

  .orange-circle {
    width: 200px;
    margin-bottom: 10px;
  }

  .title-dash-expo {
    font-size: 38px;
  }
}

@media screen and (max-width: 958px) {
  .expositor1 {
    display: flex;
    flex-direction: column;
    height: 30rem;
    text-align: center;
  }

  .expo-logo {
    width: 70px;
    margin-top: 10px;
  }

  .orange-circle {
    width: 150px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .title-dash-expo {
    font-size: 26px;
  }
}

@media (min-width: 769px) {
  .menu-bar {
    display: none;
  }

  .profile-bar {
    display: flex;
    justify-content: flex-end;
    margin-left: 110px;
  }

  .cont-profile {
    margin-right: 20px;
    margin-left: 110px;
  }
}

@media (max-width: 768px) {
  .profile-bar {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
  }
}