.expositor1 {
  display: flex;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 22rem;
  margin-top: 25px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 25px;
}

@media (min-width: 768px) {

  .expositor1,
  .novalue-solicit,
  .novalue-solicit2,
  .cont-negociacao,
  .multiple-options,
  .cont-solicit-aprovada {
    display: flex;
    margin-left: 110px;
    margin-right: 20px;
  }

  .cont-solicit-aberto {
    margin-left: 110px;
  }
}

@media (max-width: 768px) {

  .expositor1,
  .novalue-solicit,
  .novalue-solicit2,
  .multiple-options,
  .cont-negociacao {
    margin-left: 20px;
    margin-right: 20px;
  }

  .cont-solicit-aberto {
    margin-left: 20px;
    display: flex !important;
    justify-content: center !important;
  }
}

.expo1 {
  display: flex;
}

.btn-open {
  min-width: 130px;
  height: 40px;
  color: orange;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  /* position: relative; */
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid white;
  background: white;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-right: 10px;
  margin-left: 10px;
}

.btn-open:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-open1,
.btn-open2,
.btn-open3 {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-right: 10px;
  margin-left: 10px;
}

.cont-solicit-aberto {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cont-solicit-aprovada {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cont-negociacao {
  display: flex;
  flex-direction: column;
}

.solicits-open,
.solicits-aprovada {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  width: 30rem;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 24rem;
  margin-top: 25px;
  margin-right: 20px;
}



.negociacao-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 58rem;
  margin-top: 25px;
}

.novalue-solicit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff1d6;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  height: 6rem;
  margin-top: 25px;
}

.novalue-solicit2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ebeef0;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  height: 6rem;
  margin-top: 25px;
}

.solicits-open>img,
.solicits-aprovada>img {
  width: 130px;
}

.link-solicit {
  color: blue;
}

.detailes-solicit {
  min-width: 130px;
  height: 30px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #8492a2;
  background: #8492a2;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  font-weight: 400;
}

.detailes-solicit:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.pagar-aprovadas {
  min-width: 130px;
  height: 30px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  font-weight: 400;
}

.pagar-aprovadas:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.gestor-aprovada {
  min-width: 130px;
  height: 30px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #70dd35;
  background: #70dd35;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  font-weight: 400;
}

.gestor-aprovada:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btns-solict {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {

  .btns-solict,
  .warning-solicit {
    margin-left: 110px;
  }
}

@media screen and (max-width: 768px) {

  .btns-solict,
  .warning-solicit,
  .btn-new-order {
    margin-left: 20px !important;
  }
}

.company-name {
  text-align: center;
  color: #808080;
  font-size: 34px;
}

.company-name2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.title-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 24px;
  font-weight: 900;
  background-color: #e3e3e3;
  height: 6vh;
  margin-top: 10px;
}

.rating-negoc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.star-rating {
  width: 30px;
  height: 30px;
}

.estimated-price {
  display: flex;
}

.warning-value {
  display: flex;
}

.warning-solicit {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.status-solicit {
  color: #414141;
  font-size: 24px;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 0px;
}

.btn-new-order {
  min-width: 150px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #22da28;
  background: #22da28;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-left: 110px;
  margin-top: 20px;
}

.btn-new-order:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.img-company {
  width: 250px;
  border-radius: 6px;
}

.price-tag {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
  font-family: "Montserrat";
  font-weight: 400;
  color: black;
}

.cont-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.div-plan {
  display: flex;
  justify-content: center;
  background-color: #f6f6f6;
  width: 100%;
  margin-top: 10px;
  border-radius: 0.375rem;
}

.price-plan {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Montserrat";
  font-weight: 400;
}

.cont-companies {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  width: 50%;
}

.div-input-date {
  display: flex;
  width: 100%;
  justify-content: center;
}

.input-date {
  width: 100%;
  background: #f3f3f3;
  border: #f3f3f3;
  height: 60px;
  border-radius: 0.375rem;
}

.title-deadline {
  font-family: "Montserrat";
  font-weight: 400;
  color: black;
  font-size: 20px;
}

.general-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.general-price {
  display: flex;
  flex-direction: column;
}

.btns-negoc,
.btn-choose {
  margin-top: 30px;
}

.div-choose {
  display: flex;
  justify-content: flex-end;
}

.btn-briefing {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #adb5bd;
  background: #adb5bd;
}

.btn-briefing:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-chat {
  margin-left: 10px;
  min-width: 90px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  z-index: 0;
  background: #fff;
  overflow: hidden;
  border: 2px solid #adb5bd;
  color: #adb5bd;
}

.btn-chat:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-choose {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #22da28;
  background: #22da28;
}

.btn-choose:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.cont-geral {
  height: 240vh;
  background-color: #f5f5f9;
}

@media screen and (max-width: 898px) {
  .cont-solicit-aprovada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .solicits-open,
  .solicits-aprovada {
    width: 200px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1240px) {
  .general-container {
    display: flex;
    flex-direction: column;
  }

  .general-price {
    margin-left: auto;
    margin-right: auto;
  }

  .negociacao-open {
    height: 111rem;
  }

  .cont-geral {
    height: 200vh;
  }
}

@media screen and (max-width: 342px) {
  .img-company {
    width: 200px;
  }
}

@media screen and (max-width: 309px) {
  .btn-chat {
    margin-top: 20px;
  }
}

@media screen and (min-width: 690px) and (max-width: 998px) {
  .expositor1 {
    display: flex;
    flex-direction: row;
  }

  .title-dash-expo {
    font-size: 38px !important;
  }

  .expo-logo {
    width: 130px;
    margin-top: 10px;
  }

  .orange-circle {
    width: 200px;
    margin-bottom: 10px;
  }

  .title-dash-expo {
    font-size: 38px;
  }
}

@media screen and (max-width: 690px) {
  .expositor1 {
    display: flex;
    flex-direction: column;
    height: 30rem;
    text-align: center;
  }

  .expo-logo {
    width: 55px !important;
    margin-top: 10px;
  }

  .orange-circle {
    width: 90px !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .title-dash-expo {
    font-size: 26px;
  }

  .btn-open {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 878px) {
  .cont-companies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .negociacao-open {
    height: 170rem;
  }

  .div-plan {
    margin-bottom: 20px;
  }

  main {
    height: 316vh !important;
  }

  .cont-geral {
    height: 328vh;
  }
}

@media screen and (max-width: 378px) {
  .btns-solict {
    display: flex;
    justify-content: flex-start;
  }

  .btn-open2,
  .btn-open1 {
    margin-bottom: 20px;
  }
}