@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  cursor: url(./images/favicon_32x32.png), auto;
}

/* TEXT EDITOR STYLES */
.demo-editor {
  background-color: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 1.8rem;
  border-radius: 6px;
  padding: 2rem;
  width: 610px;
}

@media print {

  html,
  body {

    /* Hide the whole page */
    display: none;
  }
}

@media (max-width: 900px) {
  #features {
    padding: 2px;
    width: 100%;
    margin-top: 26rem;
  }

  #header {
    padding: 0px;
    width: 100%;
    margin-top: -101px;
  }

  #about {
    padding: 0px;
    width: 100%;
  }

  #slider {
    padding: 0px;
    width: 100%;
  }

  #advantages {
    padding: 0px;
    width: 100%;
  }

  #start-here {
    padding: 0px;
    width: 100%;
  }

  #portfolio {
    padding: 0px;
    width: 100%;
    margin-top: 100px;
  }

  #testimonials {
    padding: 0px;
    width: 100%;
    margin-top: 122px;
    height: 477px;
  }

  #form-orcamento {
    padding: 0px;
    width: 100%;
  }

  #contact {
    padding: 0px;
    width: 100%;
  }

  .demo-editor {
    width: 100%;
    height: 300px;
  }
}

.slider {
  background-color: #d9d9d9;
  height: 130px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider .slide img {
  filter: contrast(0);
}

.slide-track {
  display: flex;
  width: calc(250px * 8);
  animation: scroll 5s linear infinite;
  height: 129px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 3));
  }
}

/* pause the animation when mouse hover */
/* .slide-track:hover {
  animation-play-state: paused;
} */

.slide {
  height: 129px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 65px;
  perspective: 100px;
}

.slide img {
  width: 100%;
  transition: transform 0.5s;
}

.slide img:hover {
  transform: translateZ(20px);
}

/* GRADIENT SHADOWS */

.slide::before,
.slide::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slide::before {
  left: 0;
  right: 0;
}

.slide::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.simple-chart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#advantages {
  padding-top: 50px;
  padding-bottom: 100;
}

.row-advt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 700px;
  margin-top: 31px;
}

#advantages .circle-img {
  position: absolute;
  display: block;
  margin-top: 5px;
  left: -139px;
  width: 400px;
}

#advantages p {
  color: black;
  font-weight: 400;
  line-height: 24px;
  font-size: 18px;
  margin: 12px 0;
  padding-left: 15px;
}

#advantages h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  color: black;
  font-weight: bolder;
  margin-top: 150px;
}

#advantages .mobile-pic {
  width: 326px;
  height: 546px;
  margin-top: 100px;
}

#advantages .mob-div video {
  width: 280px;
}

@media (max-width: 768px) {
  .row-advt {
    display: flex;
    flex-direction: column;
  }

  #advantages .mob-div {
    display: flex;
    justify-content: center;
    position: relative;
    top: 500px;
  }

  #advantages .mob-div video {
    width: 200px;
  }

  .main-text2 {
    display: flex;
    justify-content: center;
    margin-top: 160px;
  }

  #services {
    margin-top: 390px;
  }

  .about-text2 {
    margin-bottom: 53px;
  }
}

@media (min-width: 531px) and (max-width: 768px) {
  #advantages .mobile-pic {
    width: 400px;
    margin-top: 185px;
    height: 564px;
    margin-bottom: 50px;
  }
}

@media (max-width: 530px) {
  #advantages .mobile-pic {
    width: 300px;
    margin-top: 185px;
    height: 454px;
    margin-bottom: 50px;
  }
}

.testimonial {
  width: 100%;
  height: 300px;
  background: #ffffff;
  box-shadow: -20px 20px #ff8400;
  border-radius: 8px;
  padding: 24px;
  font-size: 16px;
  transition: 200ms;
}

.test-1 {
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.container-4 .swiper-slide {
  margin: 20px !important;
  width: 48% !important;
}

.test-cont {
  width: 100%;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.testimonial-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
}

.container-4 .swiper-button-next {
  display: none;
}

.container-4 .swiper-button-prev {
  display: none;
}

@media (max-width: 781px) {
  .container-4 {
    display: none;
  }
}

@media (min-width: 782px) {
  .container-5 {
    display: none;
  }
}

/* .form-orcamento {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.form-orcamento-titulo h2 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
}

.form-orcamento-titulo h2 strong {
  color: #e7054c;
}

.form-orcamento-campos form textarea {
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
  font-size: 14px;
  width: 100%;
  margin-bottom: 20px;
  height: 122px !important;
}

.form-orcamento-campos form {
  align-items: stretch;
  gap: 20px;
}

.form-orcamento-campos .form1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  gap: 40px;
}

.form-orcamento-campos .form2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  gap: 40px;
}

.form-orcamento-campos form input {
  width: 24vw;
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
  font-size: 14px;
  height: 67px;
}

.form3 {
  display: flex;
  flex-direction: column;
  width: 77%;
  margin-left: auto;
  margin-right: auto;
}

.btn-send {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border-radius: 50px;
  background: #ff8400;
  height: 51px;
  border-color: #ff8400;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
}

@media (max-width: 768px) {
  .form-orcamento-campos .form1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-orcamento-campos .form2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .form-orcamento-campos form input {
    width: 90vw;
  }

  .form3 {
    width: 90vw;
  }
}

#form-orcamento {
  margin-top: 80px;
}

.rights-footer {
  margin-top: 20px;
  text-align: center;
}

.txt>.container1 {
  display: none !important;
}