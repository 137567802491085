.expositor1 {
  display: flex;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 22rem;
  margin-top: 25px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 25px;
}

.options-profile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 15rem;
  margin-right: 20px;
  min-width: 400px;
  margin-bottom: 25px;
  cursor: pointer;
}

.options-profile1 {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.375rem;
  padding: 1.75rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-right: 20px;
  width: 400px;
}

.rating-negoc1 {
  display: flex;
}

.avatar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.btns-contract {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.btn-aceitar {
  min-width: 80px;
  height: 25px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid #22da29;
  background: #22da29;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  align-items: center;
}

.btn-aceitar:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-n-aceitar {
  min-width: 100px;
  height: 25px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 0px solid rgba(218, 34, 45, 0.34);
  background: rgba(218, 34, 45, 0.34);
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  align-items: center;
}

.btn-n-aceitar:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-n-aceitar:focus {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  border: red;
  background: red;
  color: #ffff;
}

.options-profile:hover {
  box-shadow: 0 4px 10px #adb5bd;
  top: 1px;
}

.options-profile:active {
  box-shadow: 0 0 #adb5bd;
  top: 5px;
}

.title-option {
  margin-top: 10px;
}

.options-profile,
.title-option {
  font-weight: 200;
  font-size: 20px;
  color: black;
}

.options-profile,
.text-option {
  color: black;
  font-weight: 300;
}

.btns-page {
  margin-top: 20px;
}

.btn-remove {
  min-width: 130px;
  height: 40px;
  color: #8492a2;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid white;
  background: white;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-right: 10px;
  margin-left: 10px;
}

.btn-remove:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.btn-picture {
  min-width: 130px;
  height: 40px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  border-radius: 20px;
  border: 2px solid orange;
  background: orange;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  margin-right: 10px;
}

.btn-picture:hover {
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
}

.expositor4 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 61rem;
}

.expositor5 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 74rem;
}

.expositor6 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 45rem;
}

.expositor7 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 45rem;
}

.div-line {
  margin-top: 20px;
  margin-bottom: 20px;
}

.div-line2 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.line {
  margin: 1rem 0;
  color: #d9dee3;
  background-color: currentColor;
  border: 0;
  opacity: 1;
  height: 1px;
  width: 100%;
}

.address-line {
  text-align: center;
  position: absolute;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.share-infos {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 400px;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  height: 32rem;
}

.share-infos2 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 50%);
  height: 32rem;
}

.text-info {
  font-size: 20px;
  color: black;
  font-family: 'Montserrat';
}

.info-file {
  margin-top: 10px;
}

.cont-inpus-page {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cont-inpus-page2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.title-social {
  font-size: 18px;
  font-weight: 500;
}

.pass,
.google {
  display: flex;
  justify-content: space-between;
}

.div-name,
.div-email,
.div-doc,
.div-date .div-cep,
.div-cidade,
.div-gender {
  display: flex;
  justify-content: space-between;
}

/* .MuiOutlinedInput-root {
  border-radius: 0.375rem !important;
  background-color: #f3f3f3;
} */

.css-1869usk-MuiFormControl-root {
  margin-top: 11px !important;
  height: 40px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
} */

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #f3f3f3 !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1869usk-MuiFormControl-root {
  position: inherit !important;
} */

.inputs-pagina {
  display: flex;
  flex-direction: column;
  width: 75%;
}

/* label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
} */

.device-dispositives {
  margin-bottom: 40px;
}

.devices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
}

.list-devices {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
}

.form {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 0.4375rem 0.875rem;
  /* font-size: 1.1375rem; */
  font-weight: 400;
  line-height: 1.53;
  color: #343434;
  background-color: #f3f3f3;
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-name {
  height: 40px;
}

@media (min-width: 769px) {
  .expositor4,
  .expositor6,
  .expositor7,
  .expositor5 {
    display: flex;
    margin-left: 110px;
    margin-right: 20px;
  }

  .share-infos {
    margin-left: 20px;
  }

  .share-infos2 {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 848px) {
  .personal-info {
    width: 300px;
    height: 40rem;
  }
}

.div-date {
  width: 49%;
}

@media (max-width: 500px) {
  .div-name,
  .div-email,
  .div-doc,
  .div-cep,
  .div-cidade,
  .div-gender {
    display: flex;
    flex-direction: column;
  }

  .div-date {
    width: 100%;
  }

  /* label {
    margin-left: 0px !important;
  } */

  /* .MuiOutlinedInput-root {
    width: 100% !important;
  } */

  .expositor5 {
    height: 170rem !important;
  }

  .expositor6 {
    height: 87rem !important;
  }

  .expositor7 {
    height: 101rem !important;
  }

  .devices {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .expositor4,
  .expositor5 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .expositor5 {
    height: 102rem;
  }

  .expositor6 {
    height: 68rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .expositor7 {
    height: 68rem;
    margin-left: 20px;
    margin-right: 20px;
  }

  .share-infos {
    display: none;
  }

  .cont-inpus-page,
  .cont-inpus-page2 {
    display: flex;
    flex-direction: column;
  }

  .expositor4 {
    height: 96rem;
  }

  .inputs-pagina {
    width: 100%;
  }

  .btns-register {
    margin-top: 20px;
  }
}

@media screen and (min-width: 959px) and (max-width: 978px) {
  .expositor1 {
    display: flex;
    flex-direction: row;
  }

  .expo-logo {
    width: 130px;
    margin-top: 10px;
  }

  .orange-circle {
    width: 200px;
    margin-bottom: 10px;
  }

  .title-dash-expo {
    font-size: 38px;
  }
}

@media screen and (min-width: 768px) {
  .multiple-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .options-profile,
  .options-profile1 {
    margin-right: 0px;
    min-width: 0px;
  }

  main {
    height: 200vh !important;
  }
}

@media screen and (max-width: 958px) {
  .expositor1 {
    display: flex;
    flex-direction: column;
    height: 30rem;
    text-align: center;
  }

  .expo-logo {
    width: 70px;
    margin-top: 10px;
  }

  .orange-circle {
    width: 150px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .title-dash-expo {
    font-size: 26px;
  }
}
