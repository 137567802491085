.customCard {
  background-color: #ffffff;
  align-items: center;
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 30px;
  left: 20px;
  justify-content: center;
  width: 90vw;
  height: 7rem;
  padding: 3rem;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0 0 5px;
}

.customCard p {
  color: #595959;
  font-weight: 500;
  font-size: 1.2rem;
}

.customCard a {
  color: #595959;
  text-decoration: underline;
  font-weight: 500;
}

.customCard span {
  color: #595959;
  text-decoration: underline;
}

.customCard span:hover {
  cursor: pointer;
}

.btn-sendert {
  width: 20rem;
  border-radius: 50px;
  background: orange;
  height: 36px;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
}

@media (max-width: 768px) {

  .customCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    height: 16rem;
  }

}
