.expositor8 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 0.375rem;
  padding: 0 1.5rem;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  height: 98rem;
  margin-top: 20px;
}

@media (min-width: 769px) {
  .expositor8 {
    display: flex;
    margin-left: 110px;
    margin-right: 20px;
  }
}

@media (max-width: 500px) {
  .expositor8 {
    height: 168rem !important;
  }
}

@media (max-width: 768px) {
  .expositor8 {
    height: 68rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.cont-companies2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 857px) {
  .cont-companies2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 969px) {
  .div-input-date {
    display: flex;
    flex-direction: column !important;
  }

  .input-date2 {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 10px;
  }
}

.button-briefing {
  width: 100%;
  margin-top: 20px;
  padding-left: 117px;
}
