.main-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 78px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .main-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 400px; */
  }

  .button-cad {
    margin-top: 10px;
  }

  .right-side {
    border-left: none !important;
    border-top: #414141 solid 1px;
  }

  .right-side h2 {
    padding: 0 !important;
  }
}

.form-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 50px 100px 15px;
  margin-right: 20px;
  margin-top: 50px;
}

.form__input--login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  gap: 0.6rem;

}

.right-side {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-left: #414141 solid 1px;
  padding: 20px 10px 15px;
}

.right-side h2 {
  font-weight: 700;
  padding: 0px 150px;
  font-family: "Montserrat", sans-serif;
  color: #414141;
  text-align: center;
  /* width: 120%; */
  /* margin-top: 70px; */
}

a {
  color: black;
}

.form-login input {
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  background: #dfdfdf;
  font-size: 1.8rem;
  height: 40px;
}

.form-login>h2 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #414141;
  text-align: center;
  /* width: 120%; */
  /* margin-top: 70px; */
}

.form-login>label {
  font-size: 20px;
  line-height: 24px;
  color: #414141;

}

.input-line {
  padding: 10px 0;
}

.button-login {
  display: flex;
  justify-content: center;
}

.cadastre {
  display: flex;
  justify-content: center;
}

.login-error {
  color: red;
  font-size: 15px;
  position: absolute;
}

#btn-cad {
  margin-top: 30px;
}

.btn-cadastro .btn-cad {
  padding: 10px;
}

@media (min-width: 500px) and (max-width: 992px) {
  .form-login {
    width: 430px;
  }
}

@media (max-width: 499px) {
  .form-login {
    width: 350px;
  }

  .cadastre {
    text-align: center;
    width: 100%;
  }
}