@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap");
body {
  margin: 0;
  line-height: normal;
  /* overflow-y: hidden; */
}

:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-base: 14px;
  --font-size-lg: 20px;
  --font-size-xl: 23px;

  /* Colors */
  --color-white: #fff;
  --color-green: #09b001;
  --color-gray-100: #414141;
  --color-black: #000;

  /* border radiuses */
  --br-md: 50px;
}

.ellipseIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1204px;
  height: 897px;
}
.ellipseIcon1,
.rectangleDiv {
  position: absolute;
  top: 1027px;
  left: 0;
  width: 1394px;
  height: 1065px;
}
.rectangleDiv {
  top: 0;
  background-color: var(--color-white);
  box-shadow: 0 4px 31px rgba(0, 0, 0, 0.09);
  width: 1343px;
  height: 103px;
}
.bLOGDiv,
.cOMOFUNCIONADiv,
.eVENTOSDiv,
.hOMEDiv,
.lOGINDiv,
.mONTADORASDiv {
  position: absolute;
  top: 44px;
  left: 1214px;
  font-weight: 500;
  display: inline-block;
}
.bLOGDiv,
.cOMOFUNCIONADiv,
.eVENTOSDiv,
.hOMEDiv,
.mONTADORASDiv {
  left: 1084px;
}
.cOMOFUNCIONADiv,
.eVENTOSDiv,
.hOMEDiv,
.mONTADORASDiv {
  left: 924px;
}
.cOMOFUNCIONADiv,
.eVENTOSDiv,
.hOMEDiv {
  left: 772px;
}
.cOMOFUNCIONADiv,
.hOMEDiv {
  left: 558px;
}
.hOMEDiv {
  left: 437px;
}
.logoNS12Icon {
  position: absolute;
  top: 34px;
  left: 77px;
  width: 206px;
  height: 37px;
  object-fit: cover;
}
.lineDiv {
  position: absolute;
  top: 29.5px;
  left: 1160.5px;
  border-right: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1px;
  height: 44px;
}
.rectangleDiv1 {
  position: absolute;
  top: 226.45px;
  left: 132px;
  border-radius: 11px;
  background-color: var(--color-white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  width: 1068px;
  height: 591px;
}
.rectangleDiv2 {
  position: absolute;
  top: 226.45px;
  left: 127px;
  border-radius: 8px;
  background-color: var(--color-green);
  width: 1073px;
  height: 271px;
}
.cadastradoComSucesso {
  position: absolute;
  top: 551.45px;
  left: 473px;
  font-size: 35px;
  font-weight: 800;
  display: inline-block;
  width: 445px;
}
.groupIcon,
.polygonIcon {
  position: absolute;
  top: 226.45px;
  left: 1127px;
  width: 74px;
  height: 74.5px;
}
.groupIcon {
  height: 19.08%;
  width: 14.59%;
  top: 25.75%;
  right: 40.43%;
  bottom: 55.17%;
  left: 44.97%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rectangleDiv3 {
  position: absolute;
  top: 707px;
  left: 531px;
  border-radius: var(--br-md);
  background-color: var(--color-gray-100);
  width: 328px;
  height: 73px;
}
.irParaDashboard {
  position: absolute;
  top: 728px;
  left: 597px;
  font-size: var(--font-size-xl);
  display: inline-block;
  color: var(--color-white);
  text-align: left;
  width: 235px;
  height: 22px;
}
.vectorIcon {
  position: absolute;
  height: 1.72%;
  width: 1.32%;
  top: 23.08%;
  right: 11.67%;
  bottom: 75.19%;
  left: 87.01%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameDiv {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 1027px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.weveDevelopedAn {
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.blankLineP {
  margin: 0;
}
.weveDevelopedAnEffectiveP {
  /* position: absolute; */
  /* top: 436px; */
  /* left: 432px; */
  line-height: 27px;
  color: var(--color-black);
  /* display: inline-block; */
  width: 512px;
  height: 103px;
}
.rectangleDiv {
  position: absolute;
  top: -4547px;
  left: -5992px;
  background-color: var(--color-white);
  box-shadow: 0 4px 31px rgba(0, 0, 0, 0.09);
  width: 1343px;
  height: 103px;
}
.ellipseIcon,
.rectangleInput {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 836px;
  height: 623.16px;
}
.rectangleInput {
  border: 0;
  background-color: #f0efef;
  top: 394px;
  left: 337px;
  border-radius: var(--br-md);
  width: 576px;
  height: 67px;
}
.emailCadastradoDiv {
  /* position: absolute; */
  top: 364px;
  left: 347px;
  font-weight: 500;
  display: inline-block;
  width: 200px;
  height: 21px;
  width: 40%;
  text-align: start;
}
.rectangleDiv1 {
  position: absolute;
  top: 525px;
  left: 474px;
  border-radius: var(--br-md);
  background-color: var(--color-gray-100);
  width: 246px;
  height: 73px;
}
.enviarB {
  /* position: absolute; */
  top: 547px;
  left: 558px;
  font-size: var(--font-size-xl);
  display: inline-block;
  color: var(--color-white);
  width: 78px;
  height: 22px;
}
.frameDiv {
  top: 127px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0 4px 29px rgb(0 0 0 / 25%);
  width: 90%;
  height: 600px;
  overflow: hidden;
  text-align: left;
}
.sENHASpan {
  color: var(--color-green);
}
.eSQUECIASENHASENHA {
  margin-top: 105px;
  font-size: 60px;
  font-weight: 800;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 641px;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.eSQUECIASENHASENHA h2 {
  font-size: 60px;
  font-weight: 800;
}

.resetarSenhaDiv {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 800px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-gray-100);
  font-family: var(--font-montserrat);
}

.areas-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.input-email {
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  background: #dfdfdf;
  font-size: 18px;
  height: 40px;
  width: 40%;
}

.btn-pass {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  background: #414141;
  height: 51px;
  background: #414141;
  color: #ffffff;
  font-weight: 700;
  margin-top: 45px;
  font-size: 20px;
}

/* @media (min-width: 768px) {
  body {
    overflow-y: hidden;
  }
} */

@media (max-width: 767px) {
  .eSQUECIASENHASENHA {
    width: 100%;
    margin-top: 60px;
  }
  .eSQUECIASENHASENHA span {
    margin-top: 10px !important;
  }
  .weveDevelopedAnEffectiveP {
    width: 100%;
  }
  .frameDiv {
    height: 670px;
  }
  .areas-field {
    margin-top: 40px;
  }
  .resetarSenhaDiv {
    height: 840px;
  }
}

.confirm-pass {
  margin-top: 24px;
}

.frame-success img {
  width: 120px;
}
